.errorPage {
    @apply fixed h-screen w-full;
}
.errorPage .inner {
    @apply flex h-screen w-full flex-col items-center justify-center;
}
.errorPage .heading {
    @apply mb-16 text-[120px] font-bold text-primary-500 ipad:mb-9 ipad:text-[80px] mobile:mb-4 mobile:text-[50px];
}
.errorPage .subHeading {
    @apply text-4xl ipad:text-[25px] mobile:text-lg;
}
.errorPage .description {
    @apply mx-auto mb-0 max-w-[700px] justify-center text-center text-lg text-interface-400 mobile:text-base;
}
.mapPosition {
    @apply flex h-screen w-full items-center justify-center;
}
.aeroplance {
    @apply absolute left-0 right-[-140px] top-[-170px] mx-auto hidden w-[350px];
}
